<template>
  <div>
    <Loader :loading="loading" />
    <v-row>
      <v-col cols="12" md="10" sm="12">
        <v-form ref="form" onsubmit="return false;">
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pb-0">
              <v-text-field
                v-model="order.order_num"
                label="Order #"
                dense
                outlined
                :rules="[isRequired]"
                :error-messages="errorNumber"
              />
              <v-textarea
                v-model="order.instructions"
                label="Instrucciones"
                dense
                outlined
                rows="2"
              />
            </v-col>
            <v-col cols="12" md="6" sm="12" class="pb-0">
              <!-- <v-alert
                v-if="warehouses.length === 0"
                outlined
                type="info"
                text
              >
                No hay almacenes para realizar esta acción
              </v-alert> -->
              <v-text-field
                :value="user.warehouse.name"
                :label="$t('warehouseOrigin')"
                disabled
                outlined
                dense
              />
              <!-- <v-select
                v-else
                v-model="selected"
                :loading="loading"
                :items="warehouses"
                :label="$t('warehouse')"
                item-text="name"
                item-value="id"
                dense
                return-object
                @change="selectWarehouse"
                outlined
                :rules="[isRequiredObj]"
              /> -->
              <v-text-field
                :value="fatherSelected.name"
                :label="$t('warehouseDestiny')"
                dense
                disabled
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
        <Items
          :orderItems="items"
          @onRemoveItem="removeItem"
        />
      </v-col>
      <v-col cols="12" md="2" sm="12">
        <div class="d-flex justify-end mb-2">
          <v-btn
            color="primary"
            small
            tile
            elevation="0"
            @click="storeOrder"
            :disabled="!isValidateItems"
          >
            Finalizar
          </v-btn>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            color="warning"
            small
            text
            elevation="0"
            @click="getItems"
          >
            <v-icon left>mdi-refresh</v-icon>
            Skus
          </v-btn>
        </div>
        
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
// services
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import OrderServices from '@/store/services/warehouse/OrderServices';
import StatusServices from '@/store/services/general/StatusServices';
import UserWarehouseServices from '@/store/services/warehouse/UserWarehouseServices';
import InventoryServices from '@/store/services/inventory/InventoryServices'
// components
import Items from './ListItemsToReturn';
// helpers
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    Items,
  },
  data() {
    return {
      errorNumber: '',
      loading: false,
      items: [],
      warehouses: [],
      selected: null,
      fatherSelected: '',
      order: {
        user_id: '',
        origin_warehouse_id: null,
        destiny_warehouse_id: null,
        buy_contact_id: null,
        bill_contact_id: null,
        order_num: this.getOrderNum(),
        instructions: '',
        total: 0,
        delivery_date: new Date(),
        type: '',
        status_id: '',
      },
      statuses: [],
      // status: ''

      //rules
      isRequired: value => !!value || 'Campo Requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',
    }
  },
  methods: {
    removeItem(data) {
      const index = this.items.findIndex(sku => sku.id === data.sku.id);
      this.items.splice(index, 1);
    },
    async getItems() {
      try {
        const params = {
          // page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          // per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'sku.product,sku.priceables.price',
          // search_type: this.OrderType
        }
        const response = await InventoryServices.index(this.user.archon.id, this.user.warehouse.id, params);
        
        this.items = response.data.data
          .filter(item => item.qty_physical !== 0)
          .map((values) => {
            values['quantity'] = values['qty_physical']
            return values
          });
      } catch (e) {
        console.log(e)
      }
    },
    async getUserWarehouses() {
      try {
        const params = {
          // page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          // per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'warehouse',
          search_user: this.user.id
        }
        const response = await UserWarehouseServices.index(this.user.archon.id, params)
        const userWarehouse = response.data.data;
        userWarehouse.forEach(element => {
          if (element.warehouse.parent_id !== 0) {
            this.warehouses.push(element.warehouse);
          }
        });
      } catch (error) {
        console.log(error)
      }
    },
    async getFather() {
      try {
        const params = {}
        const response = await WarehouseServices.show(this.user.archon.id, this.user.warehouse.parent_id, params)
        this.fatherSelected = response.data;
      } catch (error) {
        console.log(error)
      }
    },
    async storeOrder() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Devolución',
          text: 'Hay campos requeridos',
        });
        return;
      }
      if (this.items.length === 0) {
        this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Skus',
          text: 'No existen skus para devolución de inventario',
        });
        return;
      }
      try {
        this.errorNumber = '';
        const status = this.getStatus('FAST_SHIPPING');
        this.order.status_id = status.id;
        this.order.user_id = this.user.id;
        this.order.origin_warehouse_id = this.user.warehouse.id;
        this.order.destiny_warehouse_id = this.fatherSelected.id;
        this.order.type = 'RESTORE';
        
        this.order['items'] = this.items.map(item => ({
          sku_id: item.sku_id,
          quantity: item.quantity,
          sold_price: 12,
        }));
        this.loading = true;
        await OrderServices.store(this.user.archon.id, this.order);
        this.$router.push({
          name: 'movements',
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response.status === 422) {
          const errorNumber = error.response.data.errors?.order_num[0] || null;
          if (errorNumber) {
            this.errorNumber = errorNumber;
          }
        }
      }
    },
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    getOrderNum() {
      const n = Math.round(Math.random()*999999 + 9999999);
      return `R-${n}`;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    isValidateItems() {
      return this.items.filter(sku => 
        (parseInt(sku.quantity) > parseInt(sku.qty_available)) ||
        (parseFloat(sku.sold_price) < (sku.min_sold_price))
      ).length === 0;
    },
  },
  created() {
    // this.getUserWarehouses()
    this.getStatuses();
    this.getFather();
    this.getItems()
  }
}
</script>

<style>

</style>

          found 0s    items 0     result
          1           1           0
          1           0           0
          0           1           0
          0           0           1