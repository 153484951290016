<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="orderItems"
        class="elevation-0"
        disable-pagination
        hide-default-footer
        fixed-header
        height="350"
      >
        <template v-slot:item.sku="{ item }">
          <div>
            <div>{{ item.sku.sku }}</div>
            <small class="text--secondary">{{ item.product }}</small>
          </div>
        </template>
        <template v-slot:item.qty_physical="{ item }">
          <div :class="item.quantity > item.qty_physical ? 'red--text' : 'green--text'">
            <b>{{ item.qty_physical }}</b>
          </div>
        </template>
        <template v-slot:item.quantity="{ item }">
          <input v-model="item.quantity" class="input__simple" type="number" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="removeItem(item)" small color="red" icon>
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      orderItems: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        items: this.orderItems,
      };
    },
    methods: {
      removeItem(item) {
        this.$emit('onRemoveItem', {
          sku: item,
        });
      },
    },
    computed: {
      headers() {
        return [
          {
            text: 'Sku',
            sortable: false,
            value: 'sku',
          },
          { text: 'Cantidad Física', value: 'qty_physical', sortable: false, align: 'center' },
          { text: 'Cantidad', value: 'quantity', sortable: false, align: 'center' },
          { text: '', value: 'actions', sortable: false, align: 'center' },
        ];
      },
    },
  };
  </script>
  
  <style scoped src="@/styles/InputSimple.css" />
  
  <style scoped>
  .sku-without-inventory {
    color: red;
    font-size: 20px;
  }
  </style>